import React, { useState } from 'react';
import CountUp from 'react-countup';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import AlertsComp from '../../../Components/AlertsComp';

export default function DashboardBox(props) {
    const [Alerts, setAlerts] = useState('');
    const downloadCsv = () => {
        const csvContent = [];
        const gradeMap = new Map();

        // Add headers once
        csvContent.push(['School Name', 'Ticket Number', 'Serial Number', 'Who Worked On', 'At Vendor Since']);

        props?.CsvData?.forEach((ticket, i) => {
            const grade = ticket.school_name || 'Unknown';
            if (!gradeMap.has(grade)) {
                gradeMap.set(grade, []);
            }
            const rowData = {
                'School Name': ticket?.school_name,
                'Ticket Number': ticket.ticket_num,
                'Serial Number': ticket?.serial_num,
                'Who Worked On': ticket?.whoworkedon,
                'At Vendor Since': ticket?.created_at
            };
            gradeMap.get(grade).push(rowData);
        });

        gradeMap.forEach((gradeData) => {
            gradeData.forEach((rowData) => {
                const values = Object.values(rowData);
                csvContent.push(values);
            });
        });

        if (csvContent.length > 1) { // Check if there is data (excluding headers)
            const csvString = csvContent.map(row => row.join(',')).join('\n');
            const blob = new Blob([csvString], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'AtvendorDevices.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            setAlerts(<AlertsComp show={true} variant="danger" msg={'No data available for download.'} />);
            setTimeout(function () {
                setAlerts(<AlertsComp show={false} />)
            }, 2500);
        }
    };

    return (
        <>
            {Alerts}
            <div className={`col-md-6 p-2`}>
                <div className={`${props.css} px-3 text-center h-100`}>
                    <div className="row h-100">
                        <div className="col-4 display-center py-3">
                            <img src={`/images/${props.img}`} alt="" />
                        </div>
                        <div className={`${props.flag == 1 ? 'col-6' : 'col-8'} text-center py-3`}>
                            {props.loader ?
                                <div className='row'>
                                    <div className="col-12 text-center pt-2">
                                        <img src="/images/loder.svg" className="" style={{ width: "30px" }} />
                                    </div>
                                </div>
                                :
                                <><div className="col-12 mt-2 text-one d-flex justify-content-center align-items-center">
                                    {props.headers}
                                </div>
                                    <div className={`col-12 text-two text-one ${props.textcss}`}>
                                        <CountUp end={props.AllData} />
                                    </div>

                                </>
                            }

                        </div>
                        {props.flag == 1 &&
                            <div className='col-2 p-0 h-100' style={{ borderLeft: "1px solid #09658D" }}>
                                <div className='col-12 h-50 mt-1' style={{ borderBottom: "1px solid #09658D" }}>
                                    <OverlayTrigger
                                        trigger={['hover', 'focus']}
                                        placement="auto"
                                        overlay={
                                            <Popover>
                                                <Popover.Body>Device is considered in this list if it has been at Vendor for Repair for more than 7 days.</Popover.Body>
                                            </Popover>
                                        }>
                                        <img src='/images/blueinfo.svg' className='img-fluid cursor-pointer' style={{ height: "16px" }} />
                                    </OverlayTrigger>
                                    <br />
                                    <label className='cursor-pointer' style={{ color: "#09658D", fontSize: "11px" }}>Info</label>
                                </div>
                                <div className='col-12 h-50 mt-1 cursor-pointer' onClick={downloadCsv} title='Export School Details'>
                                    <img src='/images/bluexport.svg' className=' img-fluid' style={{ height: "16px" }} />
                                    <br />
                                    <label className='cursor-pointer' style={{ color: "#09658D", fontSize: "11px" }}>Export data</label>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
