import React from 'react'
import { ApiGetCall } from '../../JS/Connector';
import { MMDDYYYY } from '../../JS/Common';
import AlertsComp from '../../Components/AlertsComp';
import GreyBoxForReports from './GreyBoxForReports';

export default function MassDevices({ setLoading, FilterData, setalerts }) {
    async function GetDeviceData() {
        setLoading(true);
        await ApiGetCall("/AllDeviceDetailsReport/" + MMDDYYYY(FilterData.StartDate) + '&' + MMDDYYYY(FilterData.EndDate) + '&' + FilterData.Location + '&' + FilterData.School).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs.length > 0) {
                    DownloadCsv(responseRs);
                } else {
                    DownloadCsv([]);
                    setalerts(<AlertsComp show={true} variant="danger" msg={"No Data Found"} />);
                    setTimeout(() => {
                        setalerts(<AlertsComp show={false} />);
                    }, 2000);
                }
                setLoading(false);
            }
        });
    }
    const DownloadCsv = (CsvData) => {
        const csvContent = [];
        const PurchaseMap = [];
        CsvData?.forEach((item) => {
            const rowData = {
                'School Name': item.school_name,
                'Total Devices': item.totalDeviceCount,
                'Devices with Student': item.withStudentDeviceCount,
                'Devices without Student': item.withoutStudentDeviceCount
            };
            PurchaseMap.push(rowData);
        });

        if (PurchaseMap.length > 0) {
            const headers = ['School Name', 'Total Devices', 'Devices with Student', 'Devices without Student'];
            csvContent.push(headers);
            PurchaseMap.forEach((rowData) => {
                const values = Object.values(rowData).map(value => {
                    if (typeof value == 'string' && value.includes(',')) {
                        return `"${value}"`;
                    }
                    return value;
                });
                csvContent.push(values);
            });
            csvContent.push([]);
            const csvString = csvContent.map((row) => row.join(',')).join('\n');
            const blob = new Blob([csvString], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'BulkDevices.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.log('No data available for download.');
        }
    };
    return (
        <GreyBoxForReports icon="faDesktop" name={"Bulk Devices Report Download"} onclickfunc={GetDeviceData} />
    )
}
