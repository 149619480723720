import React, { useState, Fragment, useRef, useContext, useEffect } from 'react'
import PageHead from '../../Components/PageHead';
import { UserContext } from '../../App';
import Cookies from 'js-cookie';
import { Camelcase, convertToBase64, getAllBuildings, getUrlParameter, LogInSchoolID, LogInUserID, typewatch } from '../../JS/Common';
import { ApiGetCall, ApiPostCall } from '../../JS/Connector';
import { useNavigate } from 'react-router-dom';
import AlertsComp from '../../Components/AlertsComp';
import { DeviceDetailsPopup } from '../../Components/DeviceDetailsPopup';
import UserDetailsPopup from '../../Components/UserDetailsPopup';
import Popup from '../../Components/Popup';
import AlertPopup from '../../Components/AlertPopup';
import { CheckValidation } from '../../Components/Validations';

export function CreateTicket() {
  const inputRef = useRef(null);
  const suggestionBoxRef = useRef(null);
  const [utils, setUtils] = useState({
    Alerts: "",
    Loading: false,
    innerload: false
  })
  const [AllData, setAllData] = useState({
    TicketId: "",
    SchoolId: "",
    DeviceID: "",
    TicketNum: "",
    TicketStatus: "",
    BuildingId: "",
    IsTicketCreated: 0
  })
  const [SendData, setSendData] = useState({
    Notes: "",
    BillableFlag: 0,
    LoanerId: ""
  })
  const [SearchForTicketValue, setSearchForTicketValue] = useState('')
  const [SuggestionBoxArray, setSuggestionBoxArray] = useState([])
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const [IsAlreadyTicketModal, setIsAlreadyTicketModal] = useState(false);
  const [DeviceIssues, setDeviceIssues] = useState([]);
  const [DevicePopup, setDevicePopup] = useState(false);
  const [UserPopup, setUserPopup] = useState(false);
  const [DeviceDetails, setDeviceDetails] = useState({});
  const [DefaultImage, setDefaultImage] = useState(true);
  const [DeviceIssueErrorMsg, setDeviceIssueErrorMsg] = useState('');
  const [BuildingArray, setBuildingArray] = useState([]);
  const [SearchForLonerValue, setSearchForLonerValue] = useState('');
  const [LonerSuggestionBoxArray, setLonerSuggestionBoxArray] = useState([]);
  const [LonerShowSuggestion, setLonerShowSuggestion] = useState('d-none');
  const [PreviewImage, setPreviewImage] = useState(false);
  const [Images, setImages] = useState([]);
  const [ModelImage, setModelImage] = useState('');
  const [deletedId, setdeletedId] = useState([]);
  const [SendOutRepair, setSendOutRepair] = useState(false);
  const [SelectedIds, setSelectedIds] = useState([]);
  const [IsBuildingChangeModal, setIsBuildingChangeModal] = useState(false);
  const [primaryLocation, setPrimaryLocation] = useState(0)
  const [ImageError, setImageError] = useState('')
  const [AlreadyTicketFlag, setAlreadyTicketFlag] = useState(3);
  const [showReworkPopup, setshowReworkPopup] = useState(false);
  const [ReworkFlag, setReworkFlag] = useState(0);
  const [NinetyDaysTickets, setNinetyDaysTickets] = useState([]);
  const [selectedReworkTicket, setSelectedReworkTicket] = useState({
    selectedReworkTicketNum: '',
    selectedReworkTicketId: ''
  })
  const [ReworkErrorMsg, setReworkErrorMsg] = useState('');

  const { UserData } = useContext(UserContext);
  const FlagForMenuAccess = UserData.MenuAccessFlag;
  const adminschoolid = Cookies.get('AdminSchoolId');
  const navigate = useNavigate();
  var BucketS3Link = process.env.REACT_APP_CDN_KEY;
  const urlDeviceId = getUrlParameter("deviceid")
  const urlTicketId = getUrlParameter("ticketid")

  useEffect(() => {
    if (FlagForMenuAccess == 5 || FlagForMenuAccess == 6) {
      setAllData({ ...AllData, SchoolId: adminschoolid });
    } else {
      setAllData({ ...AllData, SchoolId: LogInSchoolID });
    }
  }, [utils]);

  useEffect(() => {
    if (urlTicketId != "" && urlDeviceId != "") {
      setDefaultImage(false);
      setAllData((prevData) => ({
        ...prevData,
        DeviceID: urlDeviceId,
        TicketId: urlTicketId
      }));
      getInventoryDataById(urlDeviceId);
      GetDataForTicket(urlDeviceId, urlTicketId);
    }
    else if (urlDeviceId != "") { //create ticket from manage device
      setDefaultImage(false);
      setAllData((prevData) => ({
        ...prevData,
        DeviceID: urlDeviceId,
      }));
      getInventoryDataById(urlDeviceId);
    }
    GetDeviceIssue();
  }, [])

  useEffect(() => {
    if (AllData.TicketId == '') {
      if (NinetyDaysTickets?.length !== 0) {
        if (AlreadyTicketFlag == 2) {
          setshowReworkPopup(false);

        } else {
          setshowReworkPopup(true);

        }
      }
    }
    // setSelectedReworkTicket({
    //   selectedReworkTicketNum: '',
    //   selectedReworkTicketId: '',
    // });
  }, [NinetyDaysTickets]);

  //search key down for arrowup, arrowdown and enter
  const handleKeyDown = (e) => {
    setSelectedItemIndex(-1);
    const suggestionCount = SuggestionBoxArray.length;
    let arrowEventStopped = false;
    if (e.key == 'ArrowUp' && selectedItemIndex > 0) {
      e.preventDefault();
      setSelectedItemIndex(selectedItemIndex - 1);
      if (selectedItemIndex === 1) {
        arrowEventStopped = true;
      }
    } else if (e.key == 'ArrowDown' && selectedItemIndex < suggestionCount - 1) {
      e.preventDefault();
      setSelectedItemIndex(selectedItemIndex + 1);
      if (selectedItemIndex === suggestionCount - 2) {
        arrowEventStopped = true;
      }
    } else if (e.key == 'Enter') {
      e.preventDefault();
      inputRef.current.blur();
    }

    if (selectedItemIndex >= 0 && !arrowEventStopped) {
      const suggestionBox = document.querySelector('.SuggestionBoxDiv');
      const suggestionItems = document.querySelectorAll('.suggestion-item');

      if (suggestionBox && suggestionItems.length > 0) {
        const selectedRect = suggestionItems[selectedItemIndex].getBoundingClientRect();
        const suggestionBoxRect = suggestionBox.getBoundingClientRect();

        const isAbove = selectedItemIndex < suggestionCount - 1;
        const isBelow = selectedRect.bottom > suggestionBoxRect.bottom;

        if ((isAbove || isBelow) && selectedItemIndex !== suggestionCount - 1) {
          suggestionBox.scrollTop += selectedRect.top - suggestionBoxRect.top;
        }
      }
    }
  };

  const GetDeviceIssue = () => {
    if (urlTicketId == "" && urlTicketId == false) {
      ApiGetCall("/allDeviceIssue").then((result) => {
        if (result == undefined || result == "") {
          alert("Something went wrong");
        } else {
          const responseRs = JSON.parse(result);
          setUtils({ ...utils, Loading: false });
          var sugData = responseRs.msg;
          if (responseRs.response == "success") {
            if (sugData.length != 0) {
              setDeviceIssues(sugData)
            } else {
              setDeviceIssues([])
            }
          } else {
            setUtils({ ...utils, Alerts: <AlertsComp show={true} variant="danger" msg={result} /> });
            setTimeout(() => {
              setUtils({ ...utils, Alerts: <AlertsComp show={false} /> });
            }, 2000);
          }
        }
      });
    }
  }

  //store data in suggestion box
  const GetSuggestionBoxData = async (searchstring) => {
    searchstring = searchstring == "" ? null : searchstring;
    setAllData({ ...AllData, TicketId: "" });
    setUtils({ ...utils, innerload: true })
    typewatch(async function () {
      await ApiGetCall("/searchInventoryCT/" + AllData.SchoolId + "&" + searchstring).then((result) => {
        if (result == undefined || result == "") {
          alert("Something went wrong");
        } else {
          const responseRs = JSON.parse(result);
          var sugData = responseRs.msg;
          var sugArray = [];
          var i = 1;
          if (sugData.length != 0) {
            for (var i = 0; i < sugData.length; i++) {
              const lastTicketIndex = sugData[i]?.ticket?.length - 1;
              sugArray.push(
                <div key={sugData[i].id} className={`wordwrap brdr-Btm ${sugData[i].isticketcreated == 1 ? 'redText' : ''} `}>
                  <div className='row font-14' onClick={(e) => ShowDeviceDetails(e)} style={{ padding: "0px 15px" }}
                    deviceid={sugData[i].id} isticketcreatedflag={sugData[i].isticketcreated}
                    deviceticketid={sugData[i]?.ticket[lastTicketIndex]?.id}
                    ticketstatusname={sugData[i]?.ticket[lastTicketIndex]?.TicketStatusName}
                    ticketnum={sugData[i]?.ticket[lastTicketIndex]?.ticket_num}
                    ticketstatus={sugData[i]?.ticket[lastTicketIndex]?.ticket_status}>
                    <div className='col-12 d-flex justify-content-between'>
                      <div>Serial #: <b>{sugData[i].serial_number}</b></div>
                      <div>Asset Tag: <b>{sugData[i].asset_tag || '-'}</b></div>
                    </div>
                    {sugData[i]?.student_inventory?.student && (
                      <div className='col-12 d-flex justify-content-between'>
                        <div>Student #: <b>{sugData[i]?.student_inventory?.student?.student_num || '-'}</b></div>
                        <div >{sugData[i]?.student_inventory?.student?.device_user_first_name && (Camelcase(sugData[i]?.student_inventory?.student?.device_user_first_name))} {sugData[i]?.student_inventory?.student?.device_user_middle_name && (Camelcase(sugData[i]?.student_inventory?.student?.device_user_middle_name))}  {sugData[i]?.student_inventory?.student?.device_user_last_name && (Camelcase(sugData[i]?.student_inventory?.student?.device_user_last_name))}</div>
                      </div>
                    )}
                  </div>
                </div>
              )
            }
            setUtils({ ...utils, innerload: false })
            setSuggestionBoxArray(sugArray)
          } else {
            sugArray.push(
              <>
                <div className="col-12 text-center" key={i}>
                  <label>No Record Found</label>
                </div>
                <div className="col-12 d-flex align-items-center justify-content-center position-absolute pb-2" style={{ bottom: "0", borderTop: "1px solid #e1dddd" }}>
                  <label onClick={(e) => navigate("/addupdate-device")}>
                    <img src="/images/AddInventory.svg" className="img-fluid pe-2" />
                    <b className="font-18 BlackFont pt-1">Add New Device from Add Inventory</b>
                  </label>
                </div>
              </>
            );
            setUtils({ ...utils, innerload: false })
            setSuggestionBoxArray(sugArray)
          }
        }
      });
    }, 1000);
  }

  //click of device from suggestion box
  const ShowDeviceDetails = (e) => {
    var deviceid = e.currentTarget.getAttribute('deviceid') || '';
    var isticketcreatedflag = e.currentTarget.getAttribute('isticketcreatedflag') || 0;
    var deviceticketid = e.currentTarget.getAttribute('deviceticketid') || '';
    var ticketstatusname = e.currentTarget.getAttribute('ticketstatusname') || '';
    var ticketnum = e.currentTarget.getAttribute('ticketnum') || '';
    var ticketstatus = e.currentTarget.getAttribute('ticketstatus') || '';
    if (ticketstatus == 2 || ticketstatus == 7 || ticketstatus == 8) {
      deviceticketid = "";
    }
    setSearchForTicketValue('')
    setAllData((prevData) => ({
      ...prevData,
      DeviceID: deviceid,
      TicketId: deviceticketid,
      TicketNum: ticketnum,
      TicketStatus: ticketstatusname,
      IsTicketCreated: isticketcreatedflag
    }));
    if (isticketcreatedflag == 1) {
      setDefaultImage(true);
      setIsAlreadyTicketModal(true)
    } else {
      setDefaultImage(false);
    }
    getInventoryDataById(deviceid)
  }

  //get device data
  const getInventoryDataById = async (deviceid) => {
    setUtils({ ...utils, Loading: true })
    await ApiGetCall("/getInventoryDataById/" + deviceid).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        setUtils({ ...utils, Loading: false })
        const responseRs = JSON.parse(result);
        var sugData = responseRs.msg;
        const lastTicketIndex = sugData?.ticket?.length - 1;
        var buildingid = sugData?.ticket[lastTicketIndex]?.return_location;
        setDeviceDetails(sugData);
        setNinetyDaysTickets(responseRs.last_90_days_tickets_details);
        setAllData((prevData) => ({
          ...prevData,
          BuildingId: buildingid ? buildingid : sugData.building_id,
          DeviceID: sugData.id
        }));
        getAllBuildings(setBuildingArray, sugData.school_id);
      }
    });
  }

  //key down handle for loner device search
  const handleKeyDownForLoner = (event) => {
    const searchValue = event.target.value.trim();
    if (searchValue !== "" && (event.key == "Enter" || event.keyCode == 13)) {
      event.preventDefault();
      getLonerDevices(searchValue)
    }
    return false;
  }

  //search loaner devices
  const getLonerDevices = async (searchvalue) => {
    setUtils({ ...utils, Loading: true });
    setLonerShowSuggestion("");
    searchvalue = searchvalue == "" ? null : searchvalue;
    await ApiGetCall("/searchLonerforTicket/" + AllData.SchoolId + "&" + searchvalue).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        setUtils({ ...utils, Loading: false });
        var sugData = responseRs.msg;
        var sugArray = [];
        var i = 1;
        if (sugData.length != 0) {
          for (var i = 0; i < sugData.length; i++) {
            sugArray.push(
              <div className="col-md-12 d-flex justify-content-between brdr-Btm font-14" key={i} onClick={(e) => SetLoanerData(e)}
                loanerid={sugData[i].id} studentname={sugData[i].serial_number}>
                <div style={{ padding: '8px 15px' }}>{sugData[i].serial_number}</div>
              </div>
            )
          }
          setLonerSuggestionBoxArray(sugArray)
        } else {
          sugArray.push(
            <>
              <div className="col-12 text-center" key={i}>
                <label>No Record Found</label>
              </div>
            </>
          );
          setLonerSuggestionBoxArray(sugArray)
        }

      }
    });
  }

  // set loaner data in input box
  const SetLoanerData = (e) => {
    var loanerid = e.currentTarget.getAttribute('loanerid') || '';
    var studentname = e.currentTarget.getAttribute('studentname') || '';
    setSendData({ ...SendData, LoanerId: loanerid });
    setLonerShowSuggestion('d-none');
    setSearchForLonerValue(studentname);
  }

  //select image
  const handleFileUpload = async (e) => {
    const fileTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    let list = [];
    const file = e.target.files;
    for (let i = 0; i < file.length; i++) {
      const image = file[i];
      if (!fileTypes.includes(image.type)) {
        setImageError("Please upload JPEG, JPG, or PNG files only.");
        break;
      }
      let base64 = await convertToBase64(file[i])
      let ob = new Object();
      ob.img = base64;
      ob.id = 0;
      list.push(ob);
      setImageError("");
    }
    setImages(Images.concat(list))
  }

  //delete image
  async function DeleteImage(i, id) {
    setImages(Images.filter((data, j) => j !== i));
    if (id != 0) {
      let ob = new Object();
      ob.id = id;
      setdeletedId(prev => [...prev, ob])
    }

  }

  //device issue checkbox handle
  const handleDeviceIssueChange = (event, item) => {
    const isChecked = event.target.checked;
    const FlagForEdit = AllData.TicketId ? 2 : 1;
    const updatedItems = DeviceIssues.map((i) => {
      if (i.id == item.id) {
        return { ...i, Flag: isChecked ? (FlagForEdit != 2 ? 1 : 1) : (FlagForEdit != 2 ? 0 : 3) };
      }
      return i;
    });
    setDeviceIssues(updatedItems);

    if (FlagForEdit !== 2) {
      const selectedItems = updatedItems.filter((i) => i.Flag === 1);
      const selectedIDs = selectedItems.map((i) => ({ IssueID: i.id }));
      setSelectedIds(selectedIDs);
    } else {
      const filteredData = updatedItems
        .filter((i) => i.Flag === 1 || i.Flag === 3)
        .map((i) => ({ IssueID: i.id, Flag: i.Flag }));
      setSelectedIds(filteredData);
    }
  }

  //change for building
  const handleChangeForBuilding = (e) => {
    const selectedBuildingID = e.target.value;
    setAllData((prevData) => ({
      ...prevData,
      BuildingId: selectedBuildingID,
    }));
    if (selectedBuildingID !== '0') {
      setIsBuildingChangeModal(true);
    } else {
      setIsBuildingChangeModal(false);
    }
  }

  //final save for create ticket
  const createTicketCall = async (flag) => {
    var isFormValid = CheckValidation({ formID: "TicketForm" });
    if (!isFormValid) return;
    if (SelectedIds == '') {
      setDeviceIssueErrorMsg('*required');
      return;
    }
    setDeviceIssueErrorMsg('');
    setUtils({ ...utils, Loading: true });
    var lonerDeviceStatus = 0;
    var NewJson = {};
    NewJson['billableflag'] = SendData.BillableFlag;
    NewJson['Notes'] = SendData.Notes;
    NewJson['schoolId'] = AllData.SchoolId;
    NewJson['userId'] = LogInUserID;
    NewJson['inventoryId'] = DeviceDetails.id;
    NewJson['SelectedBuidingId'] = AllData.BuildingId == 0 || AllData.BuildingId == undefined ? null : AllData.BuildingId;
    if (DeviceDetails.student != null) {
      lonerDeviceStatus = 1;
      NewJson['lonerId'] = SendData.LoanerId;
      NewJson['lonerDeviceStatus'] = lonerDeviceStatus;
    } else {
      lonerDeviceStatus = 0;
      NewJson['lonerId'] = null;
      NewJson['lonerDeviceStatus'] = lonerDeviceStatus;
    }
    var raw = JSON.stringify({
      DeviceIssueArray: SelectedIds,
      msg: NewJson,
      ImageArray: Images,
      primarylocationFlag: primaryLocation,
      SendOutForTicket: SendOutRepair == true ? 1 : 0,
      TicketNumber: ReworkFlag == 1 ? selectedReworkTicket?.selectedReworkTicketNum : null,
      TicketId: ReworkFlag == 1 ? selectedReworkTicket?.selectedReworkTicketId : null,
      ReworkFlag: ReworkFlag
    });
    await ApiPostCall("/generateIssue", raw).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        if (responseRs.response == "success") {
          setUtils({ ...utils, Loading: false })
          if (flag == 2) { //create ticket & next
            setImages([]);
            setSendData({ ...SendData, Notes: "", BillableFlag: 0, LoanerId: "" })
            setAllData({ ...AllData, TicketId: "", SchoolId: "", DeviceID: "", TicketNum: "", TicketStatus: "", BuildingId: "", IsTicketCreated: 0 })
            setDefaultImage(true);
            setSelectedIds([]);
            setPrimaryLocation(0)
            navigate("/create-ticket", { replace: true })
          } else { //create ticket
            if (FlagForMenuAccess == 5 || FlagForMenuAccess == 6) {
              navigate('/school-tickets', { state: { ticketid: responseRs.ticketid } })
            } else {
              navigate('/manage-tickets', { state: { ticketid: responseRs.ticketid } })
            }
          }
        } else {
          setUtils({ ...utils, Alerts: <AlertsComp show={true} variant="danger" msg={responseRs.msg} /> })
          setTimeout(() => {
            setUtils({ ...utils, Alerts: <AlertsComp show={false} />, Loading: false })
          }, 2500);
        }
      }
    })
  }

  //update ticket call
  const UpdateTicketCall = async () => {
    var isFormValid = CheckValidation({ formID: "TicketForm" });
    if (!isFormValid) return;
    const hasSelectedItems = SelectedIds.some(item => item.Flag == 1);
    if (!hasSelectedItems) {
      setDeviceIssueErrorMsg('*required');
      return;
    }
    setDeviceIssueErrorMsg('')
    setUtils((prevdata) => ({ ...prevdata, Loading: true }));
    if (SendData.LoanerId != '') {
      var LonerStatus = 1;
    } else {
      var LonerStatus = 0;
    }
    var raw = JSON.stringify({
      SchoolId: AllData.SchoolId,
      UserId: LogInUserID,
      TicketId: AllData.TicketId,
      InventoryId: AllData.DeviceID,
      Notes: SendData.Notes,
      Issue: SelectedIds,
      IsLoner: LonerStatus,
      AssignLonerID: SendData.LoanerId,
      ImageArray: Images,
      DeleteImgArray: deletedId,
      SelectedBuidingId: AllData.BuildingId,
      primarylocationFlag: primaryLocation,
      Chargeability: SendData.BillableFlag
    });
    await ApiPostCall("/editTicketData", raw).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {

        const responseRs = JSON.parse(result);
        if (responseRs.response == "success") {
          setUtils((prevdata) => ({ ...prevdata, Loading: false, Alerts: <AlertsComp show={true} variant="success" msg={'Ticket Updated Successfully'} /> }));
          setTimeout(function () {
            setUtils((prevdata) => ({ ...prevdata, Alerts: <AlertsComp show={false} /> }));
            if (FlagForMenuAccess == 5 || FlagForMenuAccess == 6) {
              navigate('/school-tickets')
            }
            else {
              navigate('/manage-tickets')
            }
          }, 1500);
        } else {
          setUtils((prevdata) => ({ ...prevdata, Loading: false, Alerts: <AlertsComp show={true} variant="danger" msg={result} /> }));
          setTimeout(() => {
            setUtils((prevdata) => ({ ...prevdata, Loading: false, Alerts: <AlertsComp show={false} /> }));
          }, 2000);
        }
      }
    });
  }



  //again create ticket on repair billed status
  async function AgainCreateTicketCall(deviceid, ticketid, flag) {
    setUtils((prevdata) => ({ ...prevdata, Loading: true }));
    var raw = JSON.stringify({
      SchoolId: AllData.SchoolId,
      UserId: LogInUserID,
      TicketId: ticketid,
      InventoryId: deviceid,
    });
    await ApiPostCall("/ChangeRepairBilledToClose", raw).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = result;
        if (responseRs == "success") {
          if (flag == 1) {
            setAllData((prevdata) => ({ ...prevdata, TicketId: "" }));
          } else {
            setUtils({ ...utils, Loading: false, Alerts: <AlertsComp show={true} variant="success" msg={'Ticket Closed Successfully'} /> })
          }
          setTimeout(function () {
            setUtils({ ...utils, Alerts: <AlertsComp show={false} /> })
            setIsAlreadyTicketModal(false)
            setDefaultImage(false)
            getInventoryDataById(AllData.DeviceID)
          }, 1500);
        } else {
          setUtils({ ...utils, Loading: false, Alerts: <AlertsComp show={true} variant="danger" msg={result} /> })
          setTimeout(() => {
            setUtils({ ...utils, Alerts: <AlertsComp show={false} /> })
          }, 2000);
        }
      }
    });
  }

  const GetDataForTicket = async (deviceid, ticketid) => {
    await ApiGetCall("/TicketData/" + ticketid + "&" + deviceid).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        var historyDataForNotes = responseRs.msg;
        var FlagForEditTicketIssue = responseRs.issues;
        var AssignLonerData = responseRs.loner
        var imagesData = responseRs.images
        setIsAlreadyTicketModal(false);
        setAlreadyTicketFlag(3)
        if (responseRs.response == "success") {
          setDefaultImage(false)
          getInventoryDataById(deviceid)
          setSendData((prevData) => ({
            ...prevData, Notes: historyDataForNotes.notes, BillableFlag: historyDataForNotes?.chargeability
          }))
          // setAllData((prevdata) => ({ ...prevdata, BuildingId: historyDataForNotes?.return_location == null ? responseRs?.inventoryData?.building_id : historyDataForNotes?.return_location }))
          if (FlagForEditTicketIssue.length != 0) {
            setDeviceIssues(FlagForEditTicketIssue)
          }
          if (AssignLonerData != '') {
            setSearchForLonerValue(AssignLonerData)
          }
          setImages(imagesData);
          const defaultSelectedItems = FlagForEditTicketIssue.filter((i) => i.Flag == 1);
          const defaultSelectedIDs = defaultSelectedItems.map((i) => ({ IssueID: i.id, Flag: i.Flag }));
          setSelectedIds(defaultSelectedIDs)
        } else {
          setUtils({ ...utils, Alerts: <AlertsComp show={true} variant="danger" msg={result} /> })
          setTimeout(() => {
            setUtils({ ...utils, Alerts: <AlertsComp show={false} /> })
          }, 2000);
        }
      }
    });
  }

  //rework modal body
  const ReworkModalBody = (
    <>
      <p>Is this issue a repeat of the following previous tickets due to part failure or incomplete repair?</p>
      {NinetyDaysTickets?.map((ticket, index) => (
        <div className="d-flex py-1" key={index}>
          <input
            className="form-check-input"
            type="radio"
            name="reworkFlag"
            onChange={() => { setSelectedReworkTicket({ ...selectedReworkTicket, selectedReworkTicketId: ticket?.ticket_id, selectedReworkTicketNum: ticket?.ticket_number }) }}
            checked={selectedReworkTicket?.selectedReworkTicketNum == ticket?.ticket_number}
          />
          <label className='ps-3'>
            Ticket Number <b>{ticket?.ticket_number}</b> has following issues: <b>{ticket?.issues.length > 1 ? ticket?.issues.join(', ') : ticket?.issues[0]}</b>. Notes: <b>{ticket?.notes}</b>. Its Status is <b>{ticket?.status_name}. </b>
          </label>
        </div>
      ))}
      {ReworkErrorMsg && (
        <div className='redText'>{ReworkErrorMsg}</div>
      )}
    </>
  )
  const ticketmodalbody = (
    <>
      There is currently an open ticket on this device <br />(Ticket Num :{AllData.TicketNum}). The current ticket status of this ticket is {AllData.TicketStatus}.
      {AllData.TicketStatus == 'Repair Billed' && (
        <div className='col-md-12 mt-3'>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name='ticket'
              onChange={(e) => setAlreadyTicketFlag(1)}
            />
            <label className="form-check-label">Open New Ticket, Close Current Ticket</label>
          </div>
          <div className="form-check my-1">
            <input
              className="form-check-input"
              type="radio"
              name='ticket'
              onChange={(e) => setAlreadyTicketFlag(0)}
            />
            <label className="form-check-label">Edit Current Ticket</label>
          </div>
          {NinetyDaysTickets.length > 0 && (
            <div className="form-check my-1">
              <input
                className="form-check-input"
                type="radio"
                name='ticket'
                onChange={(e) => { setAlreadyTicketFlag(2); getInventoryDataById(AllData.DeviceID) }}
              />
              <label className="form-check-label">Rework</label>
            </div>
          )}
        </div>
      )}
      {AlreadyTicketFlag == 2 && (
        <>
          <hr />
          {ReworkModalBody}
        </>
      )}
    </>
  )

  const handleMarkRework = () => {
    if (!selectedReworkTicket?.selectedReworkTicketNum) {
      setReworkErrorMsg('*Please select one ticket');
    }
    else {
      setReworkErrorMsg('');
      setReworkFlag(1);
      setshowReworkPopup(false);
      setIsAlreadyTicketModal(false);
      if (AlreadyTicketFlag == 2) {
        AgainCreateTicketCall(AllData.DeviceID, AllData.TicketId, 1)
      }
    }
  }
  const handleNoRework = () => {
    setReworkErrorMsg(0);
    setshowReworkPopup(false);
    setIsAlreadyTicketModal(false);
    if (AlreadyTicketFlag == 2) {
      setDefaultImage(false);
      getInventoryDataById(AllData.DeviceID)
    }
  }
  const showButton = (AllData.TicketStatus === 'Repair Billed' && (AlreadyTicketFlag == 0 || AlreadyTicketFlag == 1)) || AllData.TicketStatus != 'Repair Billed';
  const modalfooter = (
    <>
      {AlreadyTicketFlag == 2 ?
        <>
          <button className="Cancel-btn" onClick={handleNoRework}>
            Not a Rework
          </button>
          <button className="SaveBtn" onClick={handleMarkRework}>
            Mark as Rework
          </button>
        </>
        :
        <>
          <label className="ms-2 cursor-pointer"
            onClick={() => {
              setDefaultImage(true);
              setIsAlreadyTicketModal(false);
              setAllData(prevData => ({ ...prevData, TicketId: "", SchoolId: "", DeviceID: "", TicketNum: "", TicketStatus: "", BuildingId: "", IsTicketCreated: 0 }));
              setAlreadyTicketFlag(3);
            }}>
            <u>Cancel</u>
          </label>
          {showButton && (
            <button
              className="SaveBtn"
              onClick={() => {
                if (AllData.TicketStatus == 'Repair Billed') {
                  if (AlreadyTicketFlag == 0) {
                    GetDataForTicket(AllData.DeviceID, AllData.TicketId);
                  } else if (AlreadyTicketFlag == 1) {
                    AgainCreateTicketCall(AllData.DeviceID, AllData.TicketId, 2);
                  }
                } else {
                  GetDataForTicket(AllData.DeviceID, AllData.TicketId);
                }
              }}
            >
              {AllData.TicketStatus == 'Repair Billed' ?
                AlreadyTicketFlag == 0 ? 'Edit Ticket' :
                  AlreadyTicketFlag == 1 ? 'Create Ticket' : ''
                :
                'Edit Ticket'}
            </button>
          )}
        </>
      }
    </>
  )

  return (
    <Fragment>
      <PageHead heading={AlreadyTicketFlag == 1 ? 'Create Ticket' : AllData.TicketId == "" ? 'Create Ticket' : 'Edit Ticket'}
        loading={utils.Loading} Alerts={utils.Alerts} showsearch={false} />
      <div className="container-fluid px-0 pt-2">
        <div className="GridBox p-3">

          {/* searchbox */}
          <div className='col-12 row'>
            {AllData.TicketId == "" && (
              <div className='col-md-7 col-12' >
                <form className="gridsearchbar">
                  <div className="position-absolute top-50 translate-middle-y search-icon ms-3 searchIcon">
                    <i className="bi bi-search"></i>
                  </div>
                  <input className="form-control" autoComplete="off" type="text" ref={inputRef}
                    placeholder="Search by Serial #, Student #, Student Name, Asset Tag" value={SearchForTicketValue}
                    onChange={(e) => setSearchForTicketValue(e.target.value)} onKeyDown={handleKeyDown}
                    onPaste={(e) => GetSuggestionBoxData(e.target.value)} onKeyUp={(e) => GetSuggestionBoxData(e.target.value)} />
                  <div className={`z-1 SuggestionBoxDiv ${SearchForTicketValue ? '' : 'd-none'}`} ref={suggestionBoxRef}>
                    {utils.innerload && (
                      <div className='row'>
                        <div className="col-12 text-center pt-2">
                          <img src="/images/loder.svg" style={{ width: "30px" }} />
                        </div>
                      </div>
                    )}
                    {SuggestionBoxArray.map((suggestion, index) => (
                      <div key={index} className={`suggestion-item ${index == selectedItemIndex ? 'selected' : ''}`}>
                        {suggestion}
                      </div>
                    ))}
                  </div>
                </form>
              </div>
            )}
            <div className='col-md-5'>
              {ReworkFlag == 1 &&
                <div className="col-12 text-end redText">
                  Ticket Number:&nbsp; <b>{selectedReworkTicket?.selectedReworkTicketNum}</b>  &nbsp;is marked as rework.
                </div>
              }
            </div>
          </div>

          {/* default image and main div */}
          {DefaultImage ?
            <div className='text-center py-3'>
              <img src='/images/createTicketImg.svg' className='img-fluid' />
            </div>
            :
            <div className='mt-3'>
              <div className='parent' id="TicketForm">
                {AllData.IsTicketCreated == 1 && (
                  <div className='col-12 text-end font-13 redText'>A ticket has been created on the device</div>
                )}

                {/* device details */}
                <div className='col-12 greyBox mt-4'>
                  <div className="row">
                    <div className="col-md-6 col-xl-4 col-lg-6 col-12 my-1">
                      Serial #:&nbsp;
                      <span className="fw-600 wordwrap">
                        {DeviceDetails?.serial_number}
                      </span>
                      <img src="/images/InfoIcon.svg" title="Show Device Details" className="img-fluid cursor-pointer px-2" onClick={() => { setDevicePopup(true) }} />
                    </div>
                    <div className="col-md-6 col-xl-4 col-lg-6 col-12 my-1">
                      Asset Tag: &nbsp;
                      <span className="fw-600">
                        {DeviceDetails?.asset_tag || '-'}
                      </span>
                    </div>
                    {DeviceDetails?.student != null && (
                      <div className="col-md-6 col-xl-4 col-lg-6 col-12 my-1">
                        Student #: &nbsp;
                        <span className="fw-600">
                          {DeviceDetails?.student.student_num}
                        </span>
                        <img src="/images/InfoIcon.svg" title="Show User Details" className="img-fluid cursor-pointer px-2" onClick={(e) => { setUserPopup(true) }} />
                      </div>
                    )}
                    {DeviceDetails?.student != null && (
                      <div className="col-md-6 col-xl-4 col-lg-6 col-12 my-1">
                        Device User Name: &nbsp;
                        <span className="fw-600">
                          {DeviceDetails.student.device_user_first_name &&
                            (Camelcase(DeviceDetails.student.device_user_first_name))}
                          {DeviceDetails.student.device_user_middle_name &&
                            (Camelcase(DeviceDetails.student.device_user_middle_name))}
                          {DeviceDetails.student.device_user_last_name &&
                            (Camelcase(DeviceDetails.student.device_user_last_name))}
                        </span>
                      </div>
                    )}
                    <div className="col-md-12 col-xl-8 col-lg-12 col-12 my-1">
                      Notes: &nbsp;
                      <span className="fw-600">
                        {DeviceDetails.notes || '-'}
                      </span>
                    </div>
                  </div>
                </div>

                {/* device issues */}
                <div className='col-12 greyBox mt-4'>
                  <small className='redText fs-11'>*Mandatory</small>
                  <div className='Header'>
                    <b className='font-18'>Device Issues</b><br />
                  </div>
                  <div className="row px-3">
                    {DeviceIssues.map((item, i) => {
                      return (
                        <div className="col-md-3 col-sm-6 pt-2" key={i}>
                          <div className="form-check">
                            <input
                              className="form-check-input cursor-pointer"
                              type="checkbox"
                              checked={item.Flag == 1}
                              onChange={(event) => handleDeviceIssueChange(event, item)}
                            />
                            <label className="form-check-label ps-1 cursor-pointer">
                              {item.issue}
                            </label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="px-1 redText">{DeviceIssueErrorMsg}</div>
                  <div className='col-md-6 pt-3'>
                    <textarea placeholder="Notes" rows="1" className="form-control" required
                      value={SendData.Notes} onChange={(e) => setSendData({ ...SendData, Notes: e.target.value })}></textarea>
                    <span className="form-text invalid-feedback">*required</span>
                  </div>
                </div>

                {/* return location, billable and assign a loner */}
                <div className='row mt-4'>
                  {/* return location */}
                  <div className={`${DeviceDetails.student ? 'col-md-4' : 'col-md-6'} px-3 py-1`}>
                    <div className='greyBox h-100'>
                      <div><b>Return Location{DeviceDetails.building_id ? '*' : ''}</b></div>
                      <select required={DeviceDetails.building_id} value={AllData.BuildingId}
                        onChange={(e) => { handleChangeForBuilding(e) }}>
                        <option value="0">Select</option>
                        {BuildingArray.map((item, j) => (
                          <option value={item.id} key={j}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      {DeviceDetails.building_id && <span className="form-text invalid-feedback"> *required  </span>}
                    </div>
                  </div>

                  {/* billable div */}
                  <div className={`${DeviceDetails.student ? 'col-md-4' : 'col-md-6'} px-3 py-1`}>
                    <div className='greyBox h-100'>
                      <div><b>Would you like to mark this ticket as billable to the student?</b></div>
                      <div className='col-md-12 mt-3 d-flex align-items-center'>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name='billable'
                            onChange={(e) => setSendData({ ...SendData, BillableFlag: 1 })}
                            checked={SendData.BillableFlag == 1}
                          />
                          <label className="form-check-label">Yes</label>
                        </div>
                        <div className="form-check ms-5">
                          <input
                            className="form-check-input"
                            type="radio"
                            name='billable'
                            onChange={(e) => setSendData({ ...SendData, BillableFlag: 0 })}
                            checked={SendData.BillableFlag != 1}
                          />
                          <label className="form-check-label">No</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* assign a loaner */}
                  <div className={`${DeviceDetails.student ? 'col-md-4 px-3 py-1' : 'd-none'}`}>
                    <div className='greyBox h-100'>
                      <div><b>Assign a Loaner</b></div>
                      <div className="row align-items-center pt-2">
                        <div className="col-12">
                          <form className="gridsearchbar" >
                            <div className="position-absolute top-50 translate-middle-y search-icon ms-3 searchIcon"><i className="bi bi-search"></i></div>
                            <input className="form-control" autoComplete="off" type="text" placeholder="Search"
                              value={SearchForLonerValue} onChange={(e) => setSearchForLonerValue(e.target.value)}
                              onKeyDown={handleKeyDownForLoner} onKeyUp={(e) => getLonerDevices(e.target.value)} />
                            <div className={`SuggestionBoxDiv z-1 ${LonerShowSuggestion}`} onMouseLeave={(e) => setLonerShowSuggestion('d-none')}>
                              {LonerSuggestionBoxArray}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* attach image */}
                <div className='col-12 greyBox mt-4'>
                  <input type="file" label="Image" name="myFile" accept=".jpg,.png,.jpeg"
                    id="UploadFileId" multiple onChange={(e) => handleFileUpload(e)} />
                  <div className="row">
                    <div className='col-md-4 col-sm-5 col-10 col-lg-4 col-xl-3'>
                      <label className="BorderBtn text-center d-flex align-items-center 
                      justify-content-around fw-600" htmlFor="UploadFileId">Attach Images
                        <label className="attach-img-icon cursor-pointer" htmlFor="UploadFileId"> + </label>
                      </label>
                    </div>
                    <div className={`col-md-12 mt-4 d-flex flex-wrap`} >
                      {Images.length > 0 &&
                        Images.map((data, i) => {
                          return (
                            <div className="col-md-2 col-xl-1 col-lg-2 col-sm-2 col-4 my-3 position-relative mx-2" key={i} style={{ border: '1px solid black' }} >
                              <img className="img-fluid cursor-pointer"
                                src={data?.id == 0 ? data?.img : `${BucketS3Link}${data?.img}`} alt=""
                                onClick={() => { setPreviewImage(true); setModelImage(data?.id == 0 ? data?.img : `${BucketS3Link}${data?.img}`) }}
                                style={{ maxHeight: '100%' }} />
                              <label className="attach-img-close-icon position-absolute" onClick={() => DeleteImage(i, data.id)}>  x </label>
                            </div>
                          )
                        })
                      }
                    </div>
                    <span className='redText'>{ImageError}</span>
                  </div>
                </div>

                {/* mark as send out for repair */}
                {AllData.TicketId == "" && (
                  <div className='d-flex align-items-center justify-content-center pt-3'>
                    <input className='form-check-input mt-0 me-2 cursor-pointer' type="checkbox"
                      checked={SendOutRepair} onChange={(e) => { setSendOutRepair(e.target.checked); }} />
                    <span className='fw-600 ps-3' style={{ fontSize: '17px' }}>
                      Mark as 'Send Out for Repair'</span>
                  </div>
                )}

                {/* save cancel buttons */}
                <div className="col-12 text-center py-3">
                  {AllData.TicketId == '' || AlreadyTicketFlag == 1 ?
                    <>
                      <button className='SaveBtn align-items-center mx-2' onClick={() => createTicketCall(2)}>
                        <span className='pt-2 fw-600'>Create Ticket & Next</span>
                        <img src='/images/DoneIcon.svg' className='img-fluid' style={{ height: '22px', width: '25px' }} />
                      </button>
                      <button className='SaveBtn align-items-center' onClick={() => createTicketCall(1)}>
                        <span className='pt-2 fw-600'>Create Ticket</span>
                        <img src='/images/DoneIcon.svg' className='img-fluid' style={{ height: '22px', width: '25px' }} />
                      </button>
                      <button className={`Cancel-btn ms-2`} onClick={(e) => FlagForMenuAccess == 5 || FlagForMenuAccess == 6 ? navigate('/school-tickets') : navigate('/manage-tickets')}>
                        <span className='pt-2 fw-600'>Cancel</span>
                      </button>
                    </>
                    :
                    <>
                      <button className='SaveBtn align-items-center' onClick={UpdateTicketCall}>
                        <span className='pt-2 fw-600'>Update Ticket</span>
                        <img src='/images/DoneIcon.svg' className='img-fluid' style={{ height: '25px' }} />
                      </button>
                      <button className={`Cancel-btn ms-2`} onClick={(e) => FlagForMenuAccess == 5 || FlagForMenuAccess == 6 ? navigate('/school-tickets') : navigate('/manage-tickets')}>
                        <span className='pt-2 fw-600'>Cancel</span>
                      </button>
                    </>
                  }
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      {DevicePopup == true && (
        <DeviceDetailsPopup Showbuttons={1} createticketbutton={false} deviceid={AllData?.DeviceID} activetab="device" isShow={DevicePopup} ClosePopup={(e) => setDevicePopup(false)} />
      )}

      {UserPopup == true && (
        <UserDetailsPopup isShow={UserPopup} ClosePopup={(e) => setUserPopup(false)} userid={DeviceDetails?.student?.id} />
      )}

      <Popup isshow={PreviewImage} size={"md"} title={"Preview Image"}
        closePopup={(e) => setPreviewImage(false)}
        modalBody={<img className="img-fluid" src={ModelImage} alt="" />} visibleSaveBtn={false} />

      <AlertPopup isshow={IsBuildingChangeModal} img={'/images/greenInfo.svg'}
        headingtext={`Make this location this device's primary building?`} text={''}
        cancelcall={(e) => { setIsBuildingChangeModal(false); setPrimaryLocation(2) }}
        canceltext={"No"} successtext={"Yes"} successcall={(e) => { setIsBuildingChangeModal(false); setPrimaryLocation(1) }} btntype={'SaveBtn'} />

      {IsAlreadyTicketModal == true && (
        <Popup isshow={IsAlreadyTicketModal} size={"md"} title={"Ticket Management"}
          closePopup={(e) => {
            setDefaultImage(true); setIsAlreadyTicketModal(false);
            setAllData((prevData) => ({
              ...prevData,
              TicketId: "",
              SchoolId: "",
              DeviceID: "",
              TicketNum: "",
              TicketStatus: "",
              BuildingId: "",
              IsTicketCreated: 0
            }));
            setAlreadyTicketFlag(3);
          }}
          modalBody={ticketmodalbody} modalfooter={modalfooter} />
      )}

      {showReworkPopup == true && (
        <Popup isshow={showReworkPopup} size={"md"} title={"Select the ticket you would like to rework."}
          hideCloseButton={true} modalBody={ReworkModalBody} visibleSaveBtn={true} btnText={"Mark as Rework"}
          handleSave={handleMarkRework} hideCancelBtn={true} extraBtn={true} btntype={false} ExtraText="Not a Rework" handleSaveForExtra={handleNoRework} />
      )}
    </Fragment>
  )
}
